<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.generic_career.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 2 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Código"
      label-for="input-code"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-code"
        name="input-code"
        v-model="$v.generic_career.code.$model"
        :state="validateState('code')"
        aria-describedby="input-code-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-code-live-feedback"
        >Este campo debe tener al menos 2 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="generic_career-description rich-text-content mr-0 mt-1"
        v-html="$v.generic_career.description.$model"
        @click="
          $bvModal.show(
            `edit-generic_career-description-modal-${generic_career.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-generic_career-description-modal-${generic_career.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="generic_career"
          :Text="generic_career.description"
          :permit_blank="true"
          @save="patchGenericCareer"
          @close="
            $bvModal.hide(
              `edit-generic_career-description-modal-${generic_career.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.generic_career.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="generic_career"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.generic_career.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteGenericCareer"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "GenericCareerForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    GenericCareer: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          code: "",
          description: "",
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generic_career: { ...this.GenericCareer },
    };
  },
  validations: {
    generic_career: {
      name: {
        required,
        minLength: minLength(2),
      },
      code: {},
      description: {
        minLength: minLength(0),
      },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.generic_career[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.generic_career.$touch();
      if (this.$v.generic_career.$anyError) {
        return;
      }
      if (isNaN(this.generic_career.id)) {
        this.createGenericCareer();
      } else this.updateGenericCareer();
    },
    patchGenericCareer(object, text) {
      this.generic_career.description = text;
    },
    createGenericCareer() {
      this.$store
        .dispatch(names.POST_GENERIC_CAREER, this.generic_career)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.generic_career",
                false,
                "Carrera Genérica"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateGenericCareer() {
      this.$store
        .dispatch(names.UPDATE_GENERIC_CAREER, this.generic_career)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.generic_career",
                false,
                "Carrera Genérica"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteGenericCareer() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.generic_career",
          false,
          "Carrera Genérica"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_GENERIC_CAREER,
            this.generic_career.id
          );
          this.$emit("deleted", this.generic_career);
        }
      });
    },
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.generic_career-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  max-height: 10em;
  overflow-y: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.generic_career-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>